import {
  InitializedResourceOptions,
  NoInfer,
  ResourceFetcher,
  createResource,
  onMount,
} from 'solid-js'

const createClientSideResource = <T, R = unknown>(
  fetcher: ResourceFetcher<true, T, R>,
  options?: InitializedResourceOptions<NoInfer<T>, true>,
) => {
  const [resource, resourceActions] = createResource(fetcher, {
    ssrLoadFrom: 'initial',
    ...options,
  })

  onMount(async () => {
    await resourceActions.refetch()
  })

  return [resource, resourceActions] as const
}

export { createClientSideResource }

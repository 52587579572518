import { For, Show } from 'solid-js'
import { Link } from '../../components/Link'
import { Flex } from '../../components/ui/Flex'
import { createClientSideResource } from '../../utils/createClientSideResource'

const fetchSongs = async () => {
  const baseUrl = window.location.origin
  const response = await fetch(`${baseUrl}/public/songs/index.json`)

  const songs = (await response.json()) as {
    name: string
    songUrl: string
  }[]

  return songs
}

export default function Page() {
  const [songs] = createClientSideResource(fetchSongs)

  return (
    <Flex
      direction="column"
      style={{ padding: `16px` }}
    >
      <h1>SazTunes</h1>

      <h2>Songs:</h2>
      <Show when={!songs.loading && songs()}>
        {(getSongs) => (
          <ul>
            <For each={getSongs()}>
              {(song) => (
                <li>
                  <Link href={`/songs/${song.name}`}>{song.name}</Link>
                </li>
              )}
            </For>
          </ul>
        )}
      </Show>
    </Flex>
  )
}
